var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flix-form-group"},[_c('transition',{attrs:{"name":"flixFadeIn"}},[(_vm.open)?_c('div',{key:_vm.open},[_c('checkForm',{attrs:{"type":_vm.open,"data":_vm.data,"callback":(newData) => {
            _vm.setSuccess()
            _vm.setOpen(false)
            _vm.callback(newData)
          }}})],1):_vm._e()]),_c('transition',{attrs:{"name":"flixFadeIn"}},[(_vm.success)?_c('div',{key:_vm.success,staticClass:"flix-alert flix-alert-success"},[_vm._v(" "+_vm._s(_vm.$t('message.successfullSaved'))+" ")]):_vm._e()]),_c('openBox',{attrs:{"open":_vm.open,"callback":(ret) => {
        _vm.success = false
        _vm.setOpen(ret)
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }