<template>
  <div class="flix-form-group">
    <transition name="flixFadeIn">
      <div v-if="open" :key="open">
        <checkForm
          :type="open"
          :data="data"
          :callback="
            (newData) => {
              setSuccess()
              setOpen(false)
              callback(newData)
            }
          "
        />
      </div>
    </transition>
    <transition name="flixFadeIn">
      <div :key="success" v-if="success" class="flix-alert flix-alert-success">
        {{ $t('message.successfullSaved') }}
      </div>
    </transition>
    <openBox
      :open="open"
      :callback="
        (ret) => {
          success = false
          setOpen(ret)
        }
      "
    />
  </div>
</template>
<script>
export default {
  components: {
    openBox() {
      return import('./open')
    },
    checkForm() {
      return import('./form')
    }
  },
  props: {
    data: Object,
    callback: Function
  },
  data() {
    return {
      open: false,
      success: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setSuccess() {
      this.success = true
      setTimeout(
        function () {
          this.success = false
        }.bind(this),
        2000
      )
    },
    setOpen(ret) {
      this.open = ret
    }
  }
}
</script>
<style lang="sass" scoped></style>
